<template>
  <div class="tab-list d-flex justify-content-center" :class="{'mobile': device == 'mobile'}" v-if="tabs.length">
    <ul>
      <li
        v-for="(item, index) in tabs"
        :key="index"
        class="tab-item white"
        :style="{ '--activeColor': activeColor || '#0A64A1' }"
        :class="{ 'is-active': activeKey == item.pkSerial }"
        @click="changeTab(item.pkSerial)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "Tabs",
  props: {
    activeKey: String,
    tabs: Array,
    activeColor: String,
  },
  data() {
    return {
      //   activeIndex: 0,
      //   tabs: [],
    };
  },
  methods: {
    changeTab(item) {
    //   console.log("itemitemitemitem:", item);
      this.$emit("toggleChangeTab", item);
    },
  },
  //   created() {
  //     this.getData();
  //   },
  //   methods: {
  //     async getData() {
  //       let data = await axios.get("/getTabs").then((res) => {
  //         return res.data.data;
  //       });
  //       let { activeIndex, tabs } = data;
  //       this.activeIndex = activeIndex;
  //       this.tabs = tabs;
  //       console.log("tabs", this.tabs);
  //     },
  //   },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.tab-list {
  background: rgba(51, 51, 51, 0.8);
  margin-top: -58px;
}

.tab-item {
  display: inline-block;
  height: 58px;
  line-height: 58px;
  padding: 0 29px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;

  //   &:hover {
  //     background-color: $primary;
  //   }

  &.is-active {
    background-color: var(--activeColor);
    opacity: 0.8;
  }
}

.mobile {
  &.tab-list {
    margin-top: -45px;
    // flex-wrap: nowrap;
    // overflow-x: scroll;
  }
  ul {
    display: flex;
    // overflow-x: scroll;
    flex-wrap: wrap;
    justify-content: center;
  }
  .tab-item {
    flex: 0 0 auto;
    height: 45px;
    line-height: 45px;
    font-size: 12px;
    padding: 0 10px;
  }
}
</style>
