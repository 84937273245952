<template>
  <div class="container-sm" :class="{'mobile': device == 'mobile'}" v-if="article">
    <div class="li d-flex align-items-center--" :class="animation">
      <div class="li-left mr25 h-flex-none" @click="bindItemClick(article.pkSerial)">
        <el-image class="img" :src="cdnUrl + article.flag" fit="cover"></el-image>
      </div>
      <div class="li-right flex d-flex flex-column justify-content-between h-overflow">
        <div class="h-overflow">
          <div class="title c3" :class="[device == 'mobile'? 'h-line-1' : '']" @click="bindItemClick(article.pkSerial)">{{ article.title }}</div>
          <div class="time mb15">{{ article.createDate }}</div>
          <div class="remark text-justify mb8" :class="[device == 'mobile'? 'h-line-2' : '']">{{ article.remark }}</div>
        </div>
        <div class="more mb5" @click="bindItemClick(article.pkSerial)">查看详情>></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HotNews",
  props: {
    // aticle: Object,
    // 列表所属栏目id
    catId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      cdnUrl: this.$config.CDN_URL,
      article: null,

      animation: '',
    };
  },
  watch: {
    catId: {
      handler: function(val) {
        if(!val) return
        this.getData()
      },
      immediate: true,
    }
  },
  created() {
    

  },
  methods: {
    // 点击列表项
    bindItemClick: function(pk) {
      this.$emit('on-list-click', pk)

    },

    getData: function() {
      this.animation = ''
      this.$http.get('/article/article/list', { params: {categoryId: this.catId, tag: 'hometop', auditStatus: 2} }).then(res => {
      let data = res.rows || []
      data.sort((a, b) => {return a.createDate >= b.createDate? -1 : 1})
      
      // console.log(data)
      this.article = data.length? data[0] : null

      this.animation = 'animation-slide-left'
      // data = data.map(item => {
      //   item.date = item.createDate.slice(8, 10);
      //   item.month = item.createDate.slice(0, 7);
      //   return item
      // });
      // this.listArr = data 
      // console.log(this.listArr)
    })
    },

  },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.container-sm {
  margin-bottom: 44px;
}
.li-left {
  width: 357px;
  cursor: pointer;
}
.img {
  width: 100%;
  height: 230px;
}
.title {
  font-size: 23px;
  font-weight: 600;
  line-height: 32px;
  cursor: pointer;
}
.time {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #999999;
}
.remark {
  font-size: 16px;
  line-height: 26px;
  color: #333333;
}
.more {
  font-size: 15px;
  line-height: 24px;
  color: $primary;
  cursor: pointer;
}

.container-sm.mobile {
  margin-bottom: 30px;
}
.mobile {
  padding: 0 10px;
  
  .li-left {
    width: 40%;
    margin-right: 10px;
  }
  .img {
    width: 100%;
    height: auto;
  }
  .title {
    font-size: 16px;
    line-height: 22px;
  }
  .time {
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 18px;
  }
  .remark {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .more {
    font-size: 14px;
  }
}
</style>
