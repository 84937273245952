<template>
  <div id="app">
    <!-- //头部菜单 -->
    <div class="top">
      <Menu></Menu>
    </div>

    <!-- 页面焦点图 -->
    <TopImg v-if="pageType != 1" img=""></TopImg>

    <!-- //tabs 二级栏目 -->
    <div v-if="pageType != 1" class="tabs-position">
      <Tabs
        :activeIndex="tab.id"
        :tabs="pageData.childen || []"
        activeColor=""
        @toggleChangeTab="getTab"
      ></Tabs>
    </div>

    <!-- //中间件 -->
    <div style="min-height: 400px">
      <SubTitle
        :title="tab.name"
        :title_en="tab.remark"
        padding_top="58px"
        padding_bottom="58px"
        v-if="pageType != 1"
      ></SubTitle>
      <component :is="pageComponent"></component>
    </div>

    <!-- //页脚 -->
    <Footer v-if="pageType != 1"></Footer>

    <IndexFooter></IndexFooter>

    <!-- <Tabs v-bind="tabs"></Tabs>
    <SubTitle v-bind="sub"></SubTitle>
    <HotNews :aticle="aticle"></HotNews>
    <SingleList v-bind="singleList"></SingleList>
    <DoubleList v-bind="doubleList"></DoubleList>
    <TimeList v-bind="timeList"></TimeList>
    <TimeList1 v-bind="timeList"></TimeList1>
    <Article :aticle="aticle"></Article>
    <Footer v-bind="footer"></Footer> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import store from "@/store";
import axios from "axios";

import Menu from "@/components/Menu.vue";
import TopImg from "@/components/TopImg.vue";
import Footer from "@/components/Footer.vue";
import IndexFooter from "@/components/IndexFooter.vue";
import Tabs from "@/components/Tabs.vue";
import SubTitle from "@/components/SubTitle.vue";
import SingleList from "@/components/SingleList.vue";
import DoubleList from "@/components/DoubleList.vue";
import TimeList from "@/components/TimeList.vue";
import TimeList1 from "@/components/TimeList1.vue";
import Article from "@/components/Article.vue";
import HotNews from "@/components/HotNews.vue";

export default {
  name: "Home",
  components: {
    Menu,
    TopImg,
    Footer,
    IndexFooter,
    Tabs,
    SubTitle,
    SingleList,
    DoubleList,
    TimeList,
    TimeList1,
    Article,
    HotNews,
  },
  data() {
    return {
      pageData: {
        id: "",
        name: "",
        remark: "",
        childen: [],
      },
      pageType: "",
      tab: {},
      pageComponent: "",
      api: "",
    };
  },
  computed: {
    ...mapGetters(["all_routes"]),
  },
  watch: {
    //通过watch来监听路由变化
    $route: function () {
      //     console.log("is.pageData.her:",this.pageData.herf);
      //     this.api=this.pageData.herf;
      //     console.log(this.$route.path)
      //     console.log("data====", this.pageData.herf);
      //   this.getPage();
    },
  },
  created() {
    // console.log("createdcreatedcreatedcreated");
    store.dispatch("menu/generateRoutes").then((res) => {
      router.addRoutes(res);
    });
    this.getPage();
  },
  methods: {
    async getPage() {
      let api = this.$route.path === "/" ? "" : this.$route.path;
      // console.log("apiasdfsdfsdfffffff", api);

      this.pageData = await axios.get(api + "/getPage").then((res) => {
        let data = res.data.data;
        let tab = data.childen[0];
        this.tab = tab;
        this.pageType = this.tab.pageType || 0;
        this.refreshComp();
        return data;
      });
    },

    getTab(data) {
      this.tab = data;
      this.pageType = data.pageType || 0;
      this.refreshComp();
    },
    refreshComp() {
      let type = this.pageType || 0;
      let component = "";
      switch (type) {
        case 1: //大轮播
          // component = "SingleList";
          break;
        case 2: //单行列表
          component = "SingleList";
          break;
        case 3: //双行列表
          component = "DoubleList";
          break;
        case 4: //时间列表
          component = "TimeList";
          break;
        case 5:
          component = "Article";
          break;
        default:
          break;
      }
      this.pageComponent = component;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: $minWidth;
}
.tabs-position {
  margin-top: -58px;
}
</style>
