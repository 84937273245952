<template>
  <div
    class="container-sm"
    :class="{'mobile': device == 'mobile'}"
    :style="{ '--activeColor': rootColor || '#0A64A1' }"
  >
    <div
      v-for="(item, index) in listArr"
      :key="index"
      class="d-flex mb44"
      :class="animation"
      :style="{animationDelay: (index + 1)*0.3 + 's'}"
    >
      <div class="li-left">
        <div class="date">
          <strong>{{ item.date }}</strong>
        </div>
        <div class="dashed-border"></div>
        <div class="month mt5">{{ item.month }}</div>
      </div>
      <div class="li-right flex text-left">
        <div class="title f20 c3 mb5" @click="bindItemClick(item.pkSerial)">
          <strong>{{ item.title }}</strong>
        </div>
        <div class="time f16 c9 mb15">{{ item.createDate }}</div>
        <div class="remark f16">{{ item.remark }}</div>
      </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: flex-end; padding: 30px 0;">
      <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="bindPageChange"></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SingleList",
  props: {
    rootColor: String,
    // 列表所属栏目id
    catId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      listArr: [],
      pageSize: 10,
      total: 0,
      pageNum: 1,

      animation: '',
    };
  },
  watch: {
    catId: {
      handler: function(val) {
        if(!val) return
        this.getData()
      },
      immediate: true,
    }
  },
  created() {
    

  },
  methods: {
    // 点击列表项
    bindItemClick: function(pk) {
      this.$emit('on-list-click', pk)

    },
    getData: function() {
      this.animation = ''
      this.$http.get('/article/article/list', { params: {categoryId: this.catId, pageSize: this.pageSize, pageNum: this.pageNum, auditStatus: 2,} }).then(res => {
        let data = res.rows || []
        data.sort((a, b) => {return a.createDate >= b.createDate? -1 : 1})
        data = data.map(item => {
          item.date = item.createDate.slice(8, 10);
          item.month = item.createDate.slice(0, 7);
          return item
        });
        this.listArr = data 
        this.total = res.total

        this.animation = 'animation-slide-right'
        // console.log(this.listArr)
      })
    },
    bindPageChange: function(e) {
      this.pageNum = e
      this.getData()

    },

  },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.li-left {
  width: 118px;
  text-align: center;
  margin-right: 44px;
  .date {
    font-size: 58px;
    font-weight: 400;
    color: var(--activeColor);
    opacity: 1;
  }
  .month {
    font-size: 26px;
    font-weight: 400;
    color: var(--activeColor);
    opacity: 1;
  }
}
.li-right {
  .title {
    cursor: pointer;
  }
  .time {
    line-height: 22px;
  }
  .remark {
    line-height: 26px;
    max-height: 52px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.dashed-border {
  height: 0;
  border-bottom: 1px dashed var(--activeColor);
}

.mobile {
  padding: 0 10px;
  .mb44 {
    margin-bottom: 30px;
  }
  .li-left {
    width: 60px;
    margin-right: 10px;
    .date {
      font-size: 20px;
    }
    .month {
      font-size: 12px;
    }
  }
  .li-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 16px;
    }
    .time {
      font-size: 12px;
      margin-bottom: 0;
    }
    .remark {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
