import axios from 'axios'
import config from '@/config'

const service = axios.create({
  baseURL: config.BASE_URL,
  timeout: '5000',
  headers: {
    'ContentType': 'application/x-www-form-urlencoded'
  },
})

service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)


export default service