<template>
    <div v-if="device == 'mobile'" class="footer-block" :class="{'mobile': device == 'mobile'}">
      <div class="container">
        <div class="footer-div text-left flex-b">
          <div class="d-flex justify-content-center flex-column c3">
            <p class="f14 copy">
              <!-- <strong>{{ title }}</strong> -->
              <strong>{{baseConfig.company}} 版权所有 </strong>
              
            </p>
            <p><strong v-if="baseConfig.developer">技术支持-{{baseConfig.developer}}</strong></p>
            <p class="f12" style="line-height: 26px;">
              Copyright©{{baseConfig.domain}} All Rights Reserved  
              <!-- Best viewed by 1024*768 , Chrome -->
              <br />
              地址：{{baseConfig.address}} | 
              客服热线：{{baseConfig.phone}} 

            </p>
          </div>
          <div class="footer-right">
            <img class="qr-code" :src="cdnUrl + baseConfig.mpcode" alt="" />
            <!-- <p class="primary f14 text-center"><strong>微信小程序</strong></p> -->
          </div>
        </div>
      </div>

    </div>

  <div v-else class="footer-block">
    <div class="container">
      <div class="footer-div text-left flex-b">
        <div class="d-flex justify-content-center flex-column c3">
          <p class="mb22 f14 copy">
            <!-- <strong>{{ title }}</strong> -->
            <strong>{{baseConfig.company}} 版权所有 如须转载请先联系本站 </strong>
            <strong v-if="baseConfig.developer">技术支持-{{baseConfig.developer}}</strong>
          </p>
          <p class="f12" style="line-height: 26px;">
            Copyright©{{baseConfig.domain}} All Rights Reserved  
            <!-- Best viewed by 1024*768 , Chrome -->
            <br />
            地址：{{baseConfig.address}} | 
            邮编：{{baseConfig.zipcode}} | 
            电子邮箱：{{baseConfig.email}} | 
            电话：{{baseConfig.phone}} 

          </p>
        </div>
        <div class="footer-right">
          <img class="qr-code" :src="cdnUrl + baseConfig.mpcode" alt="" />
          <!-- <p class="primary f14 text-center"><strong>微信小程序</strong></p> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'

export default {
  name: "Footer",
  data() {
    return {
      cdnUrl: this.$config.CDN_URL,
      title: "",
      copyright: "",
      address: "",
    };
  },
  computed: {
    ...mapState({
      baseConfig: state => state.menu.baseConfig,
    }),
  },
  created() {
    // this.getData();
  },
  methods: {
    // async getData() {
    //   let data = await axios.get("/getFooter").then((res) => {
    //     return res.data.data;
    //   });
    //   let { title, copyright, address } = data;
    //   this.title = title;
    //   this.copyright = copyright;
    //   this.address = address;
    // },
  },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.footer-block {
  background: rgba(#0A64A1, 0.1);
}
.footer-div {
  margin-top: 70px;
  padding: 40px 29px 30px;
  // border-top: 1px solid $primary;
  p {
    line-height: 19px;
  }
  .footer-right {
    .qr-code {
      display: block;
      width: 150px;
      // margin-bottom: 7px;
    }
  }
}
.mobile .footer-right {
  display: none;
}
.mobile {
  p {
    font-size: 12px;
    line-height: 18px!important;
    text-align: center;
  }
  .copy {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .footer-div {
    padding: 20px 10px 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
