<template>
  <div class="container-sm">
    <div class="list" :style="{ '--rootColor': rootColor || '#0A64A1' }">
      <div v-for="(item, index) in list" :key="index" class="li d-flex pb20">
        <div class="li-left mr24">
          <strong class="primary f29">{{ item.year }}</strong>
        </div>
        <div class="li-right flex line-height40">
          <span
            v-for="(honor, i) in item.honors"
            :key="i"
            class="inline-block mr20 mb7"
            >{{ honor }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeList1",
  props: {
    rootColor: String,
    year: String,
    list: Array,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.line-height40 {
  line-height: 40px;
}

.list {
  padding-left: 15px;
  padding-right: 15px;
  .li {
    position: relative;
    padding-left: 22px;
    &:last-child::before{
        display: none;
    }
    &::before {
      //线
      position: absolute;
      top: 13px;
      left: 7px;
      content: " ";
      height: 100%;
      width: 1px;
      background-color: var(--rootColor);
    }

    &::after {
      //点
      content: " ";
      left: 0;
      position: absolute;
      top: 13px;
      width: 15px;
      height: 15px;
      background: $primary;
      border-radius: 50%;
      opacity: 1;
    }
  }
}
</style>
