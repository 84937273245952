export default {
  // api路径
  BASE_URL: process.env.VUE_APP_BASE_URL,
  // 网上营业厅URL, 用户留言
  WT_URL: process.env.VUE_APP_WT_URL,
  // process.env.NODE_ENV === 'development'
  // 图片等资源路径
  // CDN_URL: 'https://gzwater.gzlcnet.cn/swamp',
  // CDN_URL: process.env.NODE_ENV === 'development'? 'http://homebackend.gzswater.jxlcit.com/swhome/common/getf?objName=' : '/swhome/common/getf?objName=',
  CDN_URL: process.env.VUE_APP_CDN_URL,
  sysWSYYTURL: process.env.VUE_APP_System_WSYYTUrl,
}
