import axios from 'axios'
import Layout from '@/views/Home'

import { constantRoutes } from '@/router'

import $http from '@/api'


const state = {
    routes: [],
    addRoutes: [],
    pageType:0,
    
    // 一维数组菜单
    menuArray: [],
    // 树形的菜单
    menuTree: [],

    // 轮播图
    banner: [],
    
    // 手机轮播图
    mbanner: [],

    // 基本配置
    baseConfig: {},
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        // 这里,state.routes由原先的静态路由变为了静态路由+异步路由
        state.routes = constantRoutes.concat(routes)
    },
    //
    SET_MENU_ARRAY: (state, menu) => {
        state.menuArray = menu
    },
    SET_MENU_TREE: (state, menu) => {
        state.menuTree = menu
    },

    SET_BANNER: (state, value) => {
        state.banner = value
    },
    SET_MBANNER: (state, value) => {
        state.mbanner = value
    },

    SET_BASE_CONFIG: (state, value) => {
        state.baseConfig = value
    }
}

const actions = {
    requestMenu({commit, state}) {
        return new Promise((resolve, reject) => {
            if(state.menuArray.length) return resolve(state.menuArray)

            $http.get('/category/category/list').then(res => {

                // 排序
                res.rows.sort((a, b) => a.weigh - b.weigh)

                commit('SET_MENU_ARRAY', res.rows)
          
                let treeRoutes = []
                for(let i = 0, len = res.rows.length; i < len; i++) {
                  let item = res.rows[i]
                  item.children = []
                  res.rows.forEach(ele => {
                    if(ele.pid == item.pkSerial) {
                        item.children.push(ele)
                    }
                  });
                  if(item.pid == '0') treeRoutes.push(item)
                }
                commit('SET_MENU_TREE', treeRoutes)
                return resolve(res.rows)
            }).catch(res => {
                return reject(res)
            })
        })
    },
    generateRoutes({ commit, state }) {
        return new Promise((resolve, reject) => {
            let asyncRoutes = []

            axios.get('/user/getRouter').then(res => {
                asyncRoutes = res&&res.data.data;
                asyncRoutes.map((value,index)=>{
                    value.component = Layout;
                })
                commit('SET_ROUTES', asyncRoutes)
                resolve(asyncRoutes)
              }).catch(error => {
                reject(error)
              })     
        })
    },
    // 获取配置
    requestConfig({ commit, state }) {
        return new Promise((resolve, reject) => {
            $http.get('/content/content/list').then(res => {
                // 轮播图
                let data = res.rows || []
                let banner = data.filter(item => item.type == 'carousel').map(item => item.content)
                commit('SET_BANNER', banner)
                let mbanner = []
                for(let i = 0, len = data.length; i < len; ++i) {
                    if(data[i].type != 'carousel') continue
                    let remark = /^[\{\[].*[\}\]]$/.test(data[i].remark)? JSON.parse(data[i].remark) : {}
                    if(remark.title1) mbanner.push(remark.title1)
                }
                commit('SET_MBANNER', mbanner)

                // 配置
                let config = data.find(item => item.type == 'baseconfig').content
                config = /^[\[|\{].*[\]|\}]$/.test(config)? JSON.parse(config) : []
                let configObj = {}
                for(let i = 0, len = config.length; i < len; ++i) {
                    configObj[config[i].key] = config[i].value
                }

                // console.log('11222334566', config)
                commit('SET_BASE_CONFIG', configObj)

                return resolve(data)
                
            }).catch(res => {
                return reject(res)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}