<template>
  <div id="app">
    <!-- <router-link style="margin-right:20px" v-for="route in all_routes" :key="route.path" :to="route.path">{{route.name}}</router-link> -->
    <router-view :key="key"/>
    <!-- <router-link v-for="route in all_routes" :key="route.path" :item="route" :base-path="route.path" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import store from "@/store";

export default {
  name: "Layout",
  data() {
    return {};
  },
  computed: {
    

    // ...mapGetters(["all_routes"]),
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + new Date()
        : this.$route + new Date();
    },
  },
  created() {
    this.$store.dispatch('menu/requestConfig').then(res => {
      // console.log(res)
    })
    // store.dispatch("menu/generateRoutes").then((res) => {
    //   router.addRoutes(res);
    // });
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
} */

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
