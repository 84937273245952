import store from '@/store'
import { mapGetters } from 'vuex'
const { body } = document
const WIDTH = 1200  //992 // refer to Bootstrap's responsive design

export default {
  computed: {
    ...mapGetters(['device'])
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      store.dispatch('app/toggleDevice', 'mobile')
    }
  },
  methods: {
    $_isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        console.log(isMobile)
        store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')
      }
    }
  }
}
