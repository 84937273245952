<template>
  <div
    class="container-sm"
    :class="{'mobile': device == 'mobile'}"
    :style="{ '--activeColor': rootColor || '#0A64A1' }"
  >
    <div class="list flex-b">
      <div v-for="(item, index) in listArr" :key="index" class="li-item mb29" :class="animation" :style="{animationDelay: (index + 1)*0.3 + 's'}">
        <!-- <router-link to="/"> -->
          <div class="li-inside" @click="bindItemClick(item.pkSerial)">
            <div class="primary f20 mb15 text-overflow">
              <strong>{{ item.title }}</strong>
            </div>
            <div class="primary f16 text-overflow">{{ item.remark }}</div>
            <div class="li-icon"></div>
          </div>
        <!-- </router-link> -->
      </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: flex-end; padding: 30px 0;">
      <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="bindPageChange"></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DoubleList",
  props: {
    rootColor: String,
    // list: Array,
    // 列表所属栏目id
    catId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      listArr: [],
      pageSize: 20,
      total: 0,
      pageNum: 1,

      animation: '',
    };
  },
  watch: {
    catId: {
      handler: function(val) {
        if(!val) return
        this.getData()
      },
      immediate: true,
    }
  },
  created() {
    
  },
  methods: {
    // 点击列表项
    bindItemClick: function(pk) {
      this.$emit('on-list-click', pk)

    },
    getData: function() {
      this.animation = ''
      this.$http.get('/article/article/list', { params: {categoryId: this.catId, auditStatus: 2, pageSize: this.pageSize, pageNum: this.pageNum} }).then(res => {
        let data = res.rows || []
        data.sort((a, b) => {return a.createDate >= b.createDate? -1 : 1})
        data = data.map(item => {
          item.date = item.createDate.slice(8, 10);
          item.month = item.createDate.slice(0, 7);
          return item
        });
        this.listArr = data 
        this.total = res.total

        this.animation = 'animation-slide-bottom'
        // console.log(this.listArr)
      })
    },
    bindPageChange: function(e) {
      this.pageNum = e
      this.getData()

    },
  },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.list {
  margin: 0 -15px;
  .li-item {
    width: 50%;
    padding: 0 15px;
    .li-inside {
      position: relative;
      padding: 29px 80px 28px 29px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      opacity: 1;
      border-radius: 3px;

      .li-icon {
        position: absolute;
        top: 50%;
        right: 29px;
        transform: translate(0, -50%);
        width: 29px;
        height: 29px;
        border-radius: 50%;
        background-color: $primary;
        &::after {
          content: " ";
          width: 12px;
          height: 24px;
          -webkit-mask-position: 0 0;
          mask-position: 0 0;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100%;
          mask-size: 100%;
          background-color: $white;
          //   color: rgba(255, 255, 255, 1);
          -webkit-mask-image: url(data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M2.454%206.58l1.06-1.06%205.78%205.779a.996.996%200%20010%201.413l-5.78%205.779-1.06-1.061%205.425-5.425-5.425-5.424z%22%20fill%3D%22%23B2B2B2%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
          mask-image: url(data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M2.454%206.58l1.06-1.06%205.78%205.779a.996.996%200%20010%201.413l-5.78%205.779-1.06-1.061%205.425-5.425-5.425-5.424z%22%20fill%3D%22%23B2B2B2%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -12px;
          margin-left: -5px;
        }
      }
    }
  }
}

.mobile {
  padding: 0 10px;
  .list {
    margin: 0;
    .li-item {
      padding: 0;
      width: 100%;
      margin-bottom: 15px;
      .li-inside {
        // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        box-shadow: none;
        position: relative;
        &:after {
          position: absolute;
          content: '';
          width: 200%;
          height: 200%;
          top: 0;
          left: 0;
          border: 1px solid #eee;
          transform: scale(0.5);
          transform-origin: 0 0;
        }
      }
      .f20 {
        font-size: 16px;
      }
      .f16 {
        font-size: 14px;
      }
      .li-icon {
        width: 25px;
        height: 25px;
        font-size: 16px;
      }
    }
  }

}
</style>
