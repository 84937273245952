import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
    // {
    //     path: '/login',
    //     component: () => import('@/views/Home'),
    //     hidden: true
    // },
    {
        path: '/',
        component: () => import('@/views/index'),
        name: '首页',
        meta: { title: '首页', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/navigation',
        component: () => import('@/views/navigation'),
        name: 'navigation',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    }

]

const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export default router
