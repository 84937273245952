import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 引入Element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)

// 引入全局样式
import '@/styles/index.scss'

import '@/styles/henson-ui.scss'

// 引入axios
import $http from '@/api/index.js'
Vue.prototype.$http = $http

// 引入配置
import $config from '@/config.js'
Vue.prototype.$config = $config

// 引入mixins
import mixins from '@/mixins/index.js'
Vue.mixin(mixins)

// 引入 vue-ba
import ba from 'vue-ba'
Vue.use(ba, 'a78ed8ef94014cce07ebab4d27a1ac0e');
// require('./mock'); //引入mock数据，关闭则注释该行

//document.title = '赣州水务集团南康区自来水有限公司'


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
