<template>

  <div
    class="d-flex align-items-center flex-column user-select subtitle"
    :class="[animation, device == 'mobile'? 'mobile' : '']"
    :style="{
      '--color': color || '#0A64A1',
      'padding-top': padding_top,
      'padding-bottom': padding_bottom,
    }"
  >
    <div class="title">
      <div class="title-content">
        {{ title }}
      </div>
    </div>
    <div class="title-en">{{ title_en }}</div>
  </div>

</template>

<script>
export default {
  name: "SubTitle",
  props: {
    color: String,
    title: String,
    title_en: String,
    padding_top: {
      type: String,
      default: "",
    },
    padding_bottom: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      animation: ''
    }
  },
  watch: {
    title: {
      handler: function(val) {
        this.animation = ''
        setTimeout(() => {
          this.animation = 'animation-slide-bottom'
        }, 200)
      },
      immediate: true,
    }
    
  }
};
</script>


<style lang="scss" scoped>
.title {
  position: relative;
  font-size: 35px;
  color: var(--color);

  .title-content {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: " ";
    position: absolute;
    left: -10%;
    top: 46%;
    width: 120%;
    height: 44px;
    background-color: var(--color);
    opacity: 0.1;
    z-index: 0;
  }
}
.title-en {
  font-size: 26px;
  color: #999999;
  opacity: 1;
}

.subtitle {
  transform: translateY(30px);
  opacity: 0;
}

.mobile {
  .title {
    font-size: 22px;
    &::after {
      height: 32px;
    }
  }
  .title-en {
    font-size: 18px;
  }
}

</style>
