<template>
  <!-- 手机版 -->
  <div v-if="device == 'mobile'" class="mobile h-flex h-justify-between h-align-center h-plr-10">
    <img class="logo" :src="cdnUrl + baseConfig.logo" alt="" />
    <div class="ham-box h-flex--column h-justify-between" :class="{'active': menuOpen}" @click="toggleMenu">
      <div class="line line1"></div>
      <div class="line line2"></div>
      <div class="line line3"></div>
    </div>
    <div v-if="menuOpen" class="menu-box h-flex--column" @rouchmove.stop.prevent>
      <el-menu
        default-active="curMid"
        background-color="transparent"
        text-color="#fff"
        active-text-color="#fff">
        <el-menu-item index="" @click="handleSelect('/')">
          <div slot="title" class="h-flex h-justify-between h-align-center">
            <span>首页</span>
            <i class="el-icon-arrow-right h-text-white"></i>
          </div>
        </el-menu-item>
        <el-menu-item :class="{'h-hidden' : item.name == '网上营业厅'}" :index="item.pkSerial" v-for="item in menuTree" :key="item.pkSerial" @click="handleSelect(item)">
          <div slot="title" class="h-flex h-justify-between h-align-center">
            <span>{{item.name}}</span>
            <i class="el-icon-arrow-right h-text-white"></i>
          </div>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
  <!-- 电脑版 -->
  <div v-else class="menu-div c3" @mouseleave="menuModalHide" @mouseenter="onMenuHover" @mousemove="bindMouseMove">
    <div
      class="menu"
      :class="[ menuHover? 'is-active' : isHome? 'ishome': '' ]"
      :style="bgcolor"
    >
      <div class="container">
        <div class=" flex d-flex justify-content-between">
          <div class="logo mr20"><img :src="cdnUrl + baseConfig.logo" alt="" /></div>
          <div class="menu-ul flex-s">
            <div class="menu-li d-flex flex-b c3" @mouseenter="hoverMenuKey=''">
              <router-link to='/' :style="{ '--textColor': activeTextColor || '#0A64A1' }" :class="{'act' : isHome}">首页</router-link>
            </div>
            <div
              class="menu-li d-flex flex-b c3"
              v-for="item in menuTree"
              :key="item.pkSerial"
              @mouseenter="menuModalShow(item.pkSerial)"
            >
              <!--                href="https://service.gzswater.com"-->
              <a
                v-if="item.name == '网上营业厅'"
                :class="[curMid == item.pkSerial || hoverMenuKey == item.pkSerial? 'act' : '']"
                :href="sysWSYYTURL"
                target="_blank"
                :style="{ '--textColor': activeTextColor || '#0A64A1' }"
              >网上营业厅</a>
              <router-link
                v-else
                :class="[curMid == item.pkSerial || hoverMenuKey == item.pkSerial? 'act' : '']"
                :to="{path: '/navigation', query: {mid: item.pkSerial}}"
                :style="{ '--textColor': activeTextColor || '#0A64A1' }"
              >
                {{ item.name }}
              </router-link>
            </div>
            <!-- <span class="search ml11"><i class="el-icon-search"></i></span> -->
          </div>
        </div>
      </div>
      <!-- <div style="position: absolute; top: 0;left: 0;background: rgba(255,255,255,0.1);height: 88px; width: 100%; filter: blur(50px);"></div> -->
    </div>
    <div
      v-if="curMenu.name != '网上营业厅'"
      class="menu-modal"
      :class="{ 'is-active': menuHover && Object.keys(curMenu).length}"
    >
      <div class="modal-container" v-if="Object.keys(curMenu).length">
        <div style="width: 225px; flex: none;"></div>
        <div class="flex d-flex align-items-center justify-content-between">
          <div class="menu-block">
            <div class="title-info">
              <div class="subtitle">{{ curMenu.name }}</div>
              <div class="infotext">{{ curMenu.descp }}</div>
            </div>
            <div class="sub-menu-block pb20">
              <router-link
                class="menu-item"
                :class="index == 0 ? 'act' : ''"
                v-for="(item, index) in curMenu.children"
                :key="item.pkSerial"
                :to="{path: '/navigation', query: {mid: curMenu.pkSerial, cid: item.pkSerial}}"
                >{{ item.name }}</router-link
              >
            </div>
          </div>
          <div class="imgblock">
            <!-- <img class="rectimg" :src="cdnUrl + curMenu.image" /> -->
            <el-image class="rectimg" :src="cdnUrl + curMenu.image" fit="cover"></el-image>
            <img v-if="baseConfig.mpcode" class="squarimg" :src="cdnUrl + baseConfig.mpcode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'


export default {
  name: "Menu",
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    activeTextColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cdnUrl: this.$config.CDN_URL,
      sysWSYYTURL: this.$config.sysWSYYTURL,
      // 鼠标是否在菜单上
      menuHover: false,
      // 当前hover的菜单的key
      hoverMenuKey: '',

      bgcolor: '',

      mousemove: false,


      menuOpen: false,
    };
  },
  computed: {
    ...mapState({
      menuArray: state => state.menu.menuArray,
      menuTree: state => state.menu.menuTree,
      baseConfig: state => state.menu.baseConfig,
    }),
    // 是否是首页
    isHome: function() {
      return this.$route.path == '/'
    },
    // 当前菜单的key
    curMid: function() {
      return this.$route.query.mid? this.$route.query.mid : ''
    },
    // 当前菜单
    curMenu: function() {
      return this.menuTree.find(item => item.pkSerial == this.hoverMenuKey) || {}
    },
  },
  created() {
    this.$store.dispatch('menu/requestMenu').then(res => {
      // console.log(this.menuTree)
    })
    window.addEventListener('scroll', this.onPageScroll)
  },
  methods: {
    // 监听滚动事件
    onPageScroll: function(e) {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let opacity = Math.abs(Math.round(scrollTop)) / 250;
      if(opacity > 1) opacity = 1
      if(opacity < 0.2) opacity = 0.3
      let shadow = opacity / 20;
      this.bgcolor = {background: `rgba(255, 255, 255, ${opacity})`, boxShadow: `0 5px 3px rgba(0, 0, 0, ${shadow})`}

    },
    // 鼠标进入菜单
    onMenuHover: function() {
      // setTimeout(() => {
        this.menuHover = true
        this.hoverMenuKey = ''
      // }, 300)
    },
    // 鼠标离开菜单
    menuModalHide() {
      this.menuHover = false
      this.hoverMenuKey = ''
    },
    // 鼠标移动到菜单项，展示子菜单
    menuModalShow(key) {
      if(!this.mousemove) return
      setTimeout(() => {
        this.hoverMenuKey = key

      }, 300)
    },

    // 鼠标是否移动
    bindMouseMove: function(e) {
      this.mousemove = true
    },

    // 展开/折叠菜单
    toggleMenu: function() {
      this.menuOpen = !this.menuOpen
    },
    handleSelect: function(e) {
      this.menuOpen = false
      switch(e) {
        case '/': {
          if(this.$route.path != '/')  this.$router.push({path: '/'});
          break;
        }
        default: {
          if(this.$route.query.mid != e.pkSerial) this.$router.push({path: '/navigation', query: {mid: e.pkSerial }});
          break;
        }
      }

    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.logo {
  height: 88px;
  line-height: 88px;
  img {
    height: 50px;
    vertical-align: middle;
  }
}
.menu-div {
  position: relative;
  min-width: $minWidth;
  // filter: blur(10px);
  .menu {
    // border-bottom: 1px solid #d2d2d2;
    background: rgba(255, 255, 255, 0.3);
    &.is-active {
      background-color: rgba(255,255,255, 1)!important;
    }
    &.ishome {
      background: transparent;
    }
  }

  .menu-li {
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
    padding: 0 11px;
    cursor: pointer;
    .act {
      color: var(--textColor);
    }
  }

  .search{
    cursor: pointer;
  }

//   .fade-enter-active,
//   .fade-leave-active {
//     transition: opacity 0.5s;
//   }
//   .fade-enter,
//   .fade-leave-to {
//     opacity: 0;
//   }

  .menu-modal {
    // position: absolute;
    // top: 88px;
    // left: 0;
    // width: 100%;
    // z-index: 2;

    height: 0;
    transition: ease 100ms;
    overflow: hidden;

    &.is-active {
      height: 220px;
      border-top: 1px solid #d2d2d2;
    }

    .mm-center {
      // max-width: 876px;
      // width: 70%;
      margin: 0 auto;

      .mm-name {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
      }
      .mm-info {
        line-height: 26px;
      }
      .mm-btn {
        font-weight: 600;
        & > .act {
          color: $primary;
        }
        & > :hover {
          color: $primary;
        }
      }
      .mm-center-rignt {
        margin-left: 43px;
        .img {
          width: 292px;
          height: 175px;
          object-fit: cover;
        }
      }
    }

    .mm-burder {
      position: absolute;
      right: 0px;
      top: 0;
      height: 100%;
      width: 219px;
      background: rgba(72, 175, 255, 0.1) url("../assets/logo_white.png")
        no-repeat center;
      background-size: cover;
      text-align: center;
      .qr-code {
        display: block;
        width: 98px;
        margin-bottom: 7px;
      }
    }
  }
}
.container {
  display: flex;
  justify-content: space-between;
}
.menu-li:hover {
  color: $primary;
}
.menu-modal {
  position: relative;
  // background: #fff;
  background-color: rgba(255,255,255, 0.95);
  display: flex;
  justify-content: center;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
}
.modal-container {
  width: 1200px;
  display: flex;
  // justify-content: space-between;
  align-items: center;

  .menu-block {
    flex: 1 1 1;
    max-width: 768px;
    height: 175px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-start;

    .title-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .subtitle {
        font-size: 22px;
        font-weight: bold;
        color: #0A64A1;
      }
      .infotext {
        font-size: 16px;
        padding: 10px 0;
        line-height: 26px;
        color: #333333;
        text-align: left;
      }
    }
    .sub-menu-block {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .menu-item {
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-right: 20px;
        margin-top: 15px;
        &:hover {
          color: #0A64A1;
        }
      }
    }
  }
  .imgblock {
    flex: none;
    display: flex;
    align-items: center;

    .rectimg {
      flex: none;
      width: 290px;
      height: 175px;
      background: #fafafa;
      margin-right: 20px;
    }
    @media screen and (max-width: 1630px) {
      .rectimg {
        // display: none;
        opacity: 0;
        width: 200px;
      }
    }

    .squarimg {
      position: absolute;
      right: 0;
      top: 0;
      flex: none;
      width: 220px;
      height: 220px;
      background: #fafafa;
    }
  }
}

.mobile {
  height: 64px;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  background: #FFF;
  .logo {
    height: 40px;
  }

  .ham-box {
    position: relative;
    width: 30px;
    height: 3px;
    .line {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #0A64A1;
      transition: transform 0.2s;
    }
    .line1 {top: -8px;}
    .line3 {bottom: -8px;}
    // .line2 {
    //   &::after {
    //       position: absolute;
    //       content: '';
    //       width: 100%;
    //       height: 100%;
    //       background: #0A64A1;
    //       transition: transform 0.2s;
    //     }
    //     &::before {
    //       position: absolute;
    //       content: '';
    //       width: 100%;
    //       height: 100%;
    //       transition: transform 0.2s;
    //     }
    // }
    &.active {
      .line1 {top: 0px; transform: rotate(45deg);}
      .line2 {display: none;}
      .line3 {bottom: 0px; transform: rotate(-45deg);}
      // .line1 {background: transparent;}
      // .line3 {background: transparent;}
      // .line2 {
      //   background: transparent;
      //   // &::after {
      //   //   background: #0A64A1;
      //   //   transform: rotate(-45deg);
      //   // }
      //   // &::before {
      //   //   background: #0A64A1;
      //   //   transform: rotate(45deg);
      //   // }
      // }
    }
  }
  .menu-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 64px;
    overflow: auto;
    background: rgba(#0A64A1, 0.95);
    /deep/ .el-menu {
      border: none;
    }
    /deep/ .el-submenu__title:hover {
      background: rgba(0, 0, 0, 0.2)!important;
    }
    /deep/ .el-menu-item.is-active {
      background: rgba(0, 0, 0, 0.2)!important;
    }
  }
  .h-hidden {
    display: none;
  }
}
</style>
