<template>
  <div
    class="top-div"
    :class="{'mobile': device == 'mobile'}"
    :style="{ backgroundImage: 'url(' + img + ')' }"
  >
    <!-- <img :src="defualtImg" alt=""> -->
  </div>
</template>

<script>
export default {
  name: "TopImg",
  props: {
    img: String,
  },
  data() {
    return {
      defualtImg: require("../assets/002.png"),
    };
  },
};
</script>


<style lang="scss" scoped>
.top-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 60vh;
}
.top-div.mobile {
  // height: 45vh;
  height: 250px;
}
</style>
