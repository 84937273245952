import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu'
import app from './modules/app'
import getters from './getters/index'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        menu,
        app,
    },
    getters,
    // mutations: {
    // },
    // actions: {
    // }
})
